/**
 * Webfonts
 * =============================================================================
 */
@font-face {
	font-family: 'Operator Black';
	src: url('./fonts/Operator-Black-Pro_Web.woff2') format('woff2'),
		 url('./fonts/Operator-Black-Pro_Web.woff') format('woff');
}

@font-face {
	font-family: 'Operator Mono SSm Bold';
	src: url('./fonts/OperatorMonoSSm-Bold_Web.woff2') format('woff2'),
		 url('./fonts/OperatorMonoSSm-Bold_Web.woff') format('woff');
}

@font-face {
	font-family: 'Operator Mono SSm Bold Italic';
	src: url('./fonts/OperatorMonoSSm-BoldItalic_Web.woff2') format('woff2'),
		 url('./fonts/OperatorMonoSSm-BoldItalic_Web.woff') format('woff');
}

@font-face {
	font-family: 'Operator Mono SSm Book';
	src: url('./fonts/OperatorMonoSSm-Book_Web.woff2') format('woff2'),
		 url('./fonts/OperatorMonoSSm-Book_Web.woff') format('woff');
}

@font-face {
	font-family: 'Operator Mono SSm Book Italic';
	src: url('./fonts/OperatorMonoSSm-BookItalic_Web.woff2') format('woff2'),
		 url('./fonts/OperatorMonoSSm-BookItalic_Web.woff') format('woff');
}


html {
	font-size: 10px;
}

@keyframes BodyCutoff {
	from { overflow: hidden; }
	top { overflow: visible; }
}

body {
	animation-delay: 1s;
	animation-duration: 0.8s;
	animation-name: BodyCutoff;
	animation-timing-function: cubic-bezier(1, 0, 0, 1);
	animation-fill-mode: backwards;
	background-color: rgb(247, 246, 245);
	background-color: hsl(331, 3%, 85%);
	color: rgb(100, 100, 100);
	font-family: Operator Mono SSm Book, monospace;
	font-feature-settings: "tnum";
	-webkit-font-smoothing: antialiased;
	font-size: 1.6rem;
	line-height: 1.7;
	margin: 0;
	padding: 0;
	text-rendering: optimizeLegibility;
}

html.fonts-ready body {
	color: white;
}

* { box-sizing: border-box; }
